/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React, { Fragment } from 'react';
import { Container } from '@material-ui/core';
import firebase from 'gatsby-plugin-firebase';
import PropTypes from 'prop-types';
import Drawer from './drawer';
import './layout.css';

const Layout = ({ children }) => {
  React.useEffect(() => {
    firebase.analytics();
  }, []);
  return (
    <Fragment>
      <Container>
        <Drawer />
        <main>{children}</main>
        {/* <footer style={{
          marginTop: `2rem`
        }}>
          © {new Date().getFullYear()}, Built with
          {` `}
          <a href="https://www.gatsbyjs.com">Gatsby</a>
        </footer> */}
      </Container>
    </Fragment>
  );
};

Layout.propTypes = {
  children : PropTypes.node.isRequired,
};

export default Layout;
