import * as React from 'react';
import { motion } from 'framer-motion';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import THEME from 'constants/theme';
import HomeIcon from '../icons/home';
import BlogIcon from '../icons/blog';

const variants = {
  open   : {
    y          : 0,
    opacity    : 1,
    transition : {
      y : { stiffness: 1000, velocity: -100 },
    },
  },
  closed : {
    y          : 50,
    opacity    : 0,
    transition : {
      y : { stiffness: 1000 },
    },
  },
};

export const MenuItem = ({ name, id }) => {
  const style = { border: `2px solid ${THEME.accent}`, padding: 5 };
  return (
    <motion.li variants={variants} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }} className='nav-li'>
      <AniLink
        to={name === 'Home' ? '/' : `/${name.toLowerCase()}`}
        paintDrip
        duration={1}
        hex={THEME.primary}
        style={{ textDecoration: 'none', color: 'inherent', display: 'flex' }}>
        <div className='icon-placeholder'>{renderIcon(name)}</div>
        <div className='text-placeholder' style={style}>
          <span
            style={{
              fontSize      : '1.2rem',
              fontWeight    : 'bold',
              textTransform : 'uppercase',
              padding       : 5,
              letterSpacing : '0.2rem',
              color         : THEME.text,
            }}>
            {name}
          </span>
        </div>
      </AniLink>
    </motion.li>
  );
};

function renderIcon(name) {
  switch (name) {
    case 'Home': {
      return <HomeIcon />;
    }
    case 'Blog': {
      return <BlogIcon />;
    }
    default: {
      return <HomeIcon />;
    }
  }
}
