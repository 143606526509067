import * as React from 'react';
import { motion } from 'framer-motion';
import { MenuItem } from './menu_item';

const variants = {
  open   : {
    transition : { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed : {
    transition : { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export const Navigation = () => (
  <motion.ul variants={variants} style={{ paddingLeft: 5 }} className='nav-ul'>
    {itemIds.map((data, index) => <MenuItem {...data} key={index} />)}
  </motion.ul>
);

const itemIds = [
  { id: 0, name: 'Home' },
  { id: 1, name: 'Blog' },
];
