import React from 'react';

function HomeIcon({ style = {} }) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      x='0'
      y='0'
      enableBackground='new 0 0 512 512'
      version='1.1'
      viewBox='0 0 512 512'
      xmlSpace='preserve'
      style={style}>
      <path fill='#D6CDA5' d='M345.654 362.983L164.418 362.983 193.339 336.994 316.733 336.994z' />
      <path fill='#E2D9B3' d='M140 362.98H368.78999999999996V389.97200000000004H140z' />
      <path
        fill='#F1E9C5'
        d='M437.938 336.994H72.134c-8.59 0-15.554-6.964-15.554-15.554V23.054c0-8.59 6.964-15.554 15.554-15.554h365.804c8.59 0 15.554 6.964 15.554 15.554V321.44c0 8.59-6.964 15.554-15.554 15.554z'
      />
      <path
        fill='#E2D9B3'
        d='M453.488 289.857v31.585c0 8.59-6.962 15.553-15.553 15.553H72.137c-8.59 0-15.553-6.962-15.553-15.553v-31.585c0 8.59 6.962 15.563 15.553 15.563h365.797c8.591-.001 15.554-6.973 15.554-15.563z'
      />
      <path fill='#52A397' d='M91.94 42.997H418.12V280.827H91.94z' />
      <path
        fill='#63B7A9'
        d='M418.128 42.997L418.128 72.168 180.551 280.825 91.944 280.825 91.944 229.331 307.189 42.997z'
      />
      <path fill='#F1E9C5' d='M24.636 380.21H487.36600000000004V504.5H24.636z' />
      <path fill='#E2D9B3' d='M24.636 472.5H487.36600000000004V504.51H24.636z' />
      <path
        fill='#52A397'
        d='M75.602 472.498c0 7.612-6.172 13.784-13.791 13.784-7.612 0-13.784-6.172-13.784-13.784 0-2.022.433-3.944 1.22-5.675 2.164-4.788 6.973-8.116 12.563-8.116a13.765 13.765 0 0112.564 8.116 13.668 13.668 0 011.228 5.675z'
      />
      <path
        fill='#61B4AA'
        d='M74.375 466.823a13.77 13.77 0 01-12.564 8.109c-5.59 0-10.4-3.327-12.563-8.109 2.164-4.788 6.973-8.116 12.563-8.116a13.768 13.768 0 0112.564 8.116z'
      />
      <path
        fill='#DD5478'
        d='M124.779 472.498c0 7.612-6.172 13.784-13.791 13.784-7.612 0-13.784-6.172-13.784-13.784 0-2.022.433-3.944 1.22-5.675 2.164-4.788 6.973-8.116 12.563-8.116a13.765 13.765 0 0112.564 8.116 13.668 13.668 0 011.228 5.675z'
      />
      <path
        fill='#E66480'
        d='M123.552 466.823a13.77 13.77 0 01-12.564 8.109c-5.59 0-10.4-3.327-12.563-8.109 2.164-4.788 6.973-8.116 12.563-8.116a13.768 13.768 0 0112.564 8.116z'
      />
      <path fill='#2A7182' d='M276.57 404.57H461.02V430.277H276.57z' />
      <path d='M425.628 280.829V43.001a7.5 7.5 0 00-7.5-7.5H91.944a7.5 7.5 0 00-7.5 7.5v237.828a7.5 7.5 0 007.5 7.5h326.184a7.5 7.5 0 007.5-7.5zm-15-7.5H99.444V50.501h311.184v222.828z' />
      <path d='M487.364 372.709H376.29v-9.729a7.5 7.5 0 00-7.5-7.5h-20.265L336.3 344.494h101.638c12.712 0 23.055-10.342 23.055-23.054V172.247c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5V321.44c0 4.441-3.613 8.054-8.055 8.054H72.134c-4.44 0-8.054-3.613-8.054-8.054V23.054c0-4.441 3.613-8.054 8.054-8.054h365.804c4.441 0 8.055 3.613 8.055 8.054V137.65c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5V23.054C460.992 10.342 450.648 0 437.938 0H72.134C59.422 0 49.08 10.342 49.08 23.054V321.44c0 12.712 10.342 23.054 23.054 23.054h101.639l-12.225 10.986H140a7.5 7.5 0 00-7.5 7.5v9.729H24.636a7.5 7.5 0 00-7.5 7.5V412c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-24.291H143.971V497H32.136v-54.645c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5V504.5a7.5 7.5 0 007.5 7.5h462.729a7.5 7.5 0 007.5-7.5V380.209a7.501 7.501 0 00-7.501-7.5zm-291.15-28.215h117.645l12.225 10.986H183.989l12.225-10.986zM147.5 370.48h16.881c.013 0 .025.003.038.003h181.234c.013 0 .025-.003.038-.003h15.599v2.229H147.5v-2.229zm332.364 17.229v59.373H219c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h260.864V497H158.971v-34.917H189c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-30.029V387.71h320.893v-.001z' />
      <path d='M59.2 432.267v-29.693c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v29.693c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5zM69.052 439.767a7.5 7.5 0 007.5-7.5v-29.693c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v29.693a7.5 7.5 0 007.5 7.5zM86.403 395.074a7.5 7.5 0 00-7.5 7.5v29.693c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5v-29.693a7.5 7.5 0 00-7.5-7.5zM111.255 432.267v-29.693c0-4.142-3.357-7.5-7.5-7.5s-7.5 3.358-7.5 7.5v29.693c0 4.142 3.357 7.5 7.5 7.5s7.5-3.358 7.5-7.5zM61.814 493.78c11.736 0 21.285-9.548 21.285-21.285s-9.549-21.286-21.285-21.286-21.285 9.549-21.285 21.286 9.549 21.285 21.285 21.285zm0-27.571c3.466 0 6.285 2.82 6.285 6.286s-2.819 6.285-6.285 6.285-6.285-2.819-6.285-6.285 2.82-6.286 6.285-6.286zM89.706 472.495c0 11.737 9.549 21.285 21.285 21.285s21.285-9.548 21.285-21.285-9.549-21.286-21.285-21.286-21.285 9.549-21.285 21.286zm21.285-6.286c3.466 0 6.285 2.82 6.285 6.286s-2.819 6.285-6.285 6.285-6.285-2.819-6.285-6.285 2.819-6.286 6.285-6.286zM451.695 470.075h-22.173c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h22.173c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5zM384.333 302.925c-4.143 0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5H415c4.143 0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-30.667zM249.667 424.92h19.398v5.354a7.5 7.5 0 007.5 7.5h184.448a7.5 7.5 0 007.5-7.5v-25.707a7.5 7.5 0 00-7.5-7.5H276.565a7.5 7.5 0 00-7.5 7.5v5.354h-19.398a7.5 7.5 0 100 14.999zm34.398-12.854h169.448v10.707H284.065v-10.707z' />
    </svg>
  );
}

export default HomeIcon;
